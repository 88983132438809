.trendItem {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
}
.trendItem .down,
.trendItem .up {
  margin-left: 4px;
  position: relative;
  top: 1px;
}
.trendItem .down i,
.trendItem .up i {
  font-size: 12px;
  transform: scale(0.83);
}
.trendItem .up {
  color: #f5222d;
}
.trendItem .down {
  color: #52c41a;
  top: -1px;
}
.trendItem.trendItemGrey .down,
.trendItem.trendItemGrey .up {
  color: rgba(0, 0, 0, 0.65);
}
.trendItem.reverseColor .up {
  color: #52c41a;
}
.trendItem.reverseColor .down {
  color: #f5222d;
}
